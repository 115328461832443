import React from "react"
import { graphql } from "gatsby"
import { StructuredText } from "react-datocms"
import {
  isHeading,
  isParagraph,
  renderRule,
} from "datocms-structured-text-utils"
import { Box, Text, Container, Heading, Flex, Grid } from "@theme-ui/components"
import Layout from "../components/layout"
import { getArticlePath } from "../utils/path"
import { getPageSlug } from "../utils/pageSlug"
import { GatsbyImage } from "gatsby-plugin-image"
import ImageGallery from "../components/blocks/imageGallery"
import ArticleTitle from "../components/articleTitle"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useFavicon } from "../hooks/useFavicon"
import Breadcrumbs from "../components/breadcrumbs"
import NewsletterSection from "../components/newsletterSection"
import Sponsors from "../components/sponsors"
import Embed from "../components/blocks/articleEmbed"

const Article = ({ data: { page, sponsorSection, newsletterSection } }) => {
  const favicon = useFavicon().site.faviconMetaTags

  const i18nPaths = page._allSlugLocales.map(path => {
    return {
      locale: path.locale,
      value: getArticlePath(page, path.locale),
    }
  })

  return (
    <Layout
      pageSlug={page.channel.slug}
      locale={page.locale}
      i18nPaths={i18nPaths}
    >
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <Container sx={{ pb: [0, 0] }}>
        <Box sx={{ py: [4, 4, 6], pt: [10, 10, 6] }}>
          <Breadcrumbs page={page} />
        </Box>
      </Container>

      <Container sx={{ mb: [8] }}>
        <Grid
          columns={["1fr", "1fr", "1fr", ".5fr .5fr"]}
          sx={{ backgroundColor: "primary" }}
          gap={[0]}
        >
          <Box sx={{ py: [4, 5], px: [3, 5] }}>
            <Heading as="h1" variant="h2" sx={{ color: "light" }}>
              {page.title}
            </Heading>
          </Box>
          {page.heroImage && (
            <Box sx={{ ".gatsby-image-wrapper": { width: "100%" } }}>
              <GatsbyImage image={page.heroImage.gatsbyImageData} />
            </Box>
          )}
        </Grid>
      </Container>

      <Container sx={{ mb: 8 }}>
        <Grid columns={[1, 1, ".25fr .75fr"]}>
          <Box>
            {page.meta && page.meta.firstPublishedAt && (
              <Box>
                <Text
                  sx={{
                    fontSize: [0],
                    letterSpacing: "0.09",
                    lineHeight: "1",
                    fontWeight: "500",
                  }}
                >
                  {page.meta.firstPublishedAt}
                </Text>
              </Box>
            )}
          </Box>
          <Box>
            <StructuredText
              data={page.body}
              customRules={[
                renderRule(
                  isHeading,
                  ({ adapter: { renderNode }, node, children, key }) => {
                    return renderNode(
                      () => {
                        return (
                          <Text
                            as={`h${node.level}`}
                            variant={`h${node.level}`}
                            sx={{ mb: 3 }}
                          >
                            {children}
                          </Text>
                        )
                      },
                      { key },
                      children
                    )
                  }
                ),
                renderRule(
                  isParagraph,
                  ({ adapter: { renderNode }, node, children, key }) => {
                    return renderNode(
                      () => {
                        return (
                          <Text as="p" mb={3} variant="article">
                            {children}
                          </Text>
                        )
                      },
                      { key },
                      children
                    )
                  }
                ),
              ]}
              renderBlock={({ record }) => {
                // console.log(record)
                switch (record.__typename) {
                  case "DatoCmsImageGallery":
                    return (
                      <Box mt={5} mb={5}>
                        <ImageGallery images={record.images} key={record.id} />
                      </Box>
                    )
                  case "DatoCmsEmbed":
                    return (
                      <Embed
                        title={record.title}
                        code={record.code}
                        fullWidth={record.fullWidth}
                      />
                    )
                  default:
                    return null
                }
              }}
            />
          </Box>
        </Grid>
      </Container>
    </Layout>
  )
}

export default Article

export const query = graphql`
  query ArticleQuery($id: String!, $locale: String!, $channelId: String!) {
    page: datoCmsArticle(id: { eq: $id }) {
      ...ArticleDetails
      ...ArticleAllSlugLocales
      ...ArticleMeta
      meta {
        firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    newsletterSection: allDatoCmsNewsletterSection(
      filter: {
        channel: { pageLink: { id: { eq: $channelId } } }
        locale: { eq: $locale }
      }
    ) {
      nodes {
        title
        subtitle
        link {
          ... on DatoCmsExternalLink {
            id
            anchor
            url
            model {
              apiKey
            }
          }
        }
      }
    }
    sponsorSection: allDatoCmsSponsorSection(
      filter: { channel: { pageLink: { id: { eq: $channelId } } } }
    ) {
      nodes {
        sponsors {
          name
          image {
            url
            gatsbyImageData(width: 1480, placeholder: BLURRED)
          }
          url
        }
      }
    }
  }

  fragment ArticleMeta on DatoCmsArticle {
    meta {
      firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
    }
  }

  fragment ArticleAllSlugLocales on DatoCmsArticle {
    _allSlugLocales {
      value
      locale
    }
  }

  fragment ArticleDetails on DatoCmsArticle {
    id
    locale
    title
    slug
    channel {
      id
      slug
      locale
    }
    model {
      apiKey
    }
    heroImage {
      gatsbyImageData(width: 1480, height: 986, placeholder: BLURRED)
    }
    category {
      title
      slug
      ...ArticleCategoryAllSlugLocales
    }
    ...ArticleBody
  }

  fragment ArticleBody on DatoCmsArticle {
    body {
      value
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
          ...ImageGallery
        }
        ... on DatoCmsEmbed {
          id: originalId
          ...EmbedDetails
        }
      }
    }
  }

  fragment ImageGallery on DatoCmsImageGallery {
    images {
      gatsbyImageData(width: 1480, placeholder: BLURRED)
      alt
      title
    }
    model {
      apiKey
    }
  }
`
